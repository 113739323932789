// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-ml-solution-js": () => import("./../../../src/pages/ai-ml-solution.js" /* webpackChunkName: "component---src-pages-ai-ml-solution-js" */),
  "component---src-pages-application-development-js": () => import("./../../../src/pages/application-development.js" /* webpackChunkName: "component---src-pages-application-development-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cloud-service-js": () => import("./../../../src/pages/cloud-service.js" /* webpackChunkName: "component---src-pages-cloud-service-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-[id]-js": () => import("./../../../src/pages/jobs/[id].js" /* webpackChunkName: "component---src-pages-jobs-[id]-js" */),
  "component---src-pages-managed-it-services-js": () => import("./../../../src/pages/managed-it-services.js" /* webpackChunkName: "component---src-pages-managed-it-services-js" */),
  "component---src-pages-quizzy-account-delete-request-js": () => import("./../../../src/pages/quizzy/account-delete-request.js" /* webpackChunkName: "component---src-pages-quizzy-account-delete-request-js" */),
  "component---src-pages-quizzy-privacy-policy-js": () => import("./../../../src/pages/quizzy/privacy-policy.js" /* webpackChunkName: "component---src-pages-quizzy-privacy-policy-js" */),
  "component---src-pages-team-augmentation-js": () => import("./../../../src/pages/team-augmentation.js" /* webpackChunkName: "component---src-pages-team-augmentation-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-case-template-js": () => import("./../../../src/templates/case-template.js" /* webpackChunkName: "component---src-templates-case-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

